export const CREATE_DOCUMENTS = "CREATE_DOCUMENTS";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const SET_FILTER_FUNCTION = "SET_FILTER_FUNCTION";
export const TOGGLE_EXCLUDED_DOCUMENT = "EXCLUDED_DOCUMENT";
export const SET_DOCUMENT_YEAR = "SET_DOCUMENT_YEAR";
export const ADD_TO_OVERRIDES = "ADD_TO_OVERRIDES";
export const DELETE_FROM_OVERRIDES = "DELETE_FROM_OVERRIDES";
export const SET_MAP_CONTROL = "SET_MAP_CONTROL";
export const SET_OVERRIDES = "SET_OVERRIDES";
export const CLEAR_OVERRIDES = "CLEAR_OVERRIDES";
export const INCLUDE_ALL = "INCLUDE_ALL";
export const EXCLUDE_ALL = "EXCLUDE_ALL";
